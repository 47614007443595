














































import Vue from "vue";
import firebase from "@/plugins/firebase";
import "@/types";
import { RawLocation } from "vue-router";

export default Vue.extend({
  metaInfo: {
    title: "Kullanıcı Girişi"
  },
  data: () => ({
    form: {
      email: "",
      password: ""
    },
    alert: false,
    message: "",
    error: false
  }),
  computed: {
    emailRules() {
      return [
        (v: string) => !!v || "Lütfen e-postanızı yazınız",
        (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [(v: string) => !!v || "Lütfen parolanızı yazınız"];
    },
    returnUrl(): RawLocation {
      let returnVal: RawLocation = { name: "home" };

      if (this.$route.query.returnUrl) {
        returnVal = this.$route.query.returnUrl.toString();
      } else {
        const user = this.$store.getters["auth/user"];
        if (user.roles.indexOf("admin") >= 0) {
          returnVal = { name: "admin-home" };
        } else {
          returnVal = { name: "learner-home" };
        }
      }

      return returnVal;
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form as HTMLFormElement;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    },
    signin() {
      if (this.validate()) {
        // Kullanıcı adı ve parolası ile giriş yap
        firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(async data => {
            if (!data.user) return;

            const user: User = {
              id: data.user.uid,
              displayName: data.user.displayName,
              email: data.user.email,
              firstName: null,
              lastName: null,
              roles: null
            };

            await this.$store.dispatch("auth/signIn", user);

            this.error = false;
            this.alert = true;
            this.message = "Bilgileriniz doğrulandı. Lütfen bekleyin...";

            // Check returnUrl
            this.$router.push(this.returnUrl);
          })
          .catch(err => {
            this.error = true;
            this.alert = true;
            this.message = this.getErrorMessage(err.code);
          });
      }
    },
    getErrorMessage(code: string) {
      let msg = "";
      switch (code) {
        default:
          msg = "Kullanıcı adı / parola doğrulanamadı.";
      }
      return msg;
    }
  },
  mounted() {
    this.$emit("setTitle", "Giriş");
  }
});
